import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PointRepoService } from 'src/app/services/point-repo.service';

@Component({
  selector: 'app-balance-point',
  templateUrl: './balance-point.component.html',
  styleUrls: ['./balance-point.component.scss']
})
export class BalancePointComponent implements OnInit {

  @ViewChild('mymodal') settingWindow: ElementRef;
  points: any[] = [];
  constructor(private dialogRef: MatDialogRef<BalancePointComponent>,
    @Inject(MAT_DIALOG_DATA) public dealerId: number | undefined,
    private balanceRepo: PointRepoService) {

  }

  ngOnInit(): void {
    this.getBalancePoints();
  }

  getBalancePoints(): void {
    this.balanceRepo.getBalancePoint(this.dealerId).subscribe((d) => {
      this.points = d;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { RawDataRepoService } from '../services/raw-data-repo.service';
@Component({
  selector: 'app-raw-data',
  templateUrl: './emr-raw-data.component.html',
  styleUrls: ['./emr-raw-data.component.scss']
})
export class EmrRawDataComponent implements OnInit {
  deviceId = '';
  result: any[] = [];
  constructor(private repo: RawDataRepoService) { }

  ngOnInit(): void {

  }

  setValue(): void {
    this.loadData(this.deviceId);
  }

  loadData(deviceId: string): void {
    this.repo.getEmergencyRawData(deviceId)
      .subscribe((d: any) => {
        this.result = d;
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../..//environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PoiModel } from '../models/poi-model';
import { pointReportInput } from '../dealer/point-summary-report/point-summary-report.component';

@Injectable({
  providedIn: 'root'
})
export class PointRepoService {

  constructor(private httpClient: HttpClient) { }

  getBalancePoint(dealerId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/Points/BalancePoint/${dealerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getDealerWiseBalancePoint(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/Points/BalancePoint`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  debitPoint(ToDealerId: number, PointType: number, PointCount: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiEndPoint}/api/Points/DebitPoint`, {
      ToDealerId, PointType, PointCount
    })
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  creditPoint(FromDealerId: number, PointType: number, PointCount: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiEndPoint}/api/Points/CreditPoint`, {
      FromDealerId, PointType, PointCount
    })
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  activatePointOnDevice(dealerId: number, deviceId: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiEndPoint}/api/Points/AllocateToDevice`, {
      DeviceId: deviceId, DealerId: dealerId
    })
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  pointSummary(input: pointReportInput): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiEndPoint}/api/Points/PointSummary`, input)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  last10PointSummary(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/Points/Last10Summary`)
    .pipe(map((res: any) => {
      return res.Result.Data;
    }));
  }
}

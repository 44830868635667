import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LastPointService } from 'src/app/services/last-point.service';
import { CustomerUserRepoService } from 'src/app/services/customer-user-repo.service';
import { DeviceRepoService } from 'src/app/services/device-repo.service';


@Component({
  selector: 'app-serach',
  templateUrl: './serach.component.html',
  styleUrls: ['./serach.component.scss']
})
export class SerachComponent implements OnInit {

  result: any = null;
  searchFilter: any[] = [];
  searchCtrl: any;
  search = 1;
  palceHolder = 'Device IMEI';
  value: string;
  resultDevice: any[] = [];
  resultCustomer: any[] = [];
  pointValidity: any;
  constructor(private dialogRef: MatDialogRef<SerachComponent>,
    private deviceRepo: DeviceRepoService,
    private lastRepo: LastPointService,
    private userRepo: CustomerUserRepoService,
    private toastRepo: ToastrService) { }

  ngOnInit(): void {
    this.searchFilter.push({ Id: 1, Name: 'Device IMEI' });
  }

  filterChange(event: any): void {
    this.searchFilter.forEach((value: any, index: any) => {
      if (value.Id === event.value) {
        this.palceHolder = value.Name;
        return false;
      }
    });
  }

  searchDevice(): void {
    this.pointValidity = null;
    this.result = null;
    this.resultDevice = [];
    this.resultCustomer = [];
    this.deviceRepo.openSearchDevice(this.value)
      .subscribe((data: any) => {
        this.resultDevice = data;
      }, error => {
        this.result = null;
        this.toastRepo.error(error.error.Error.Data, 'Search..');
      });
  }

  searchUser(): void {
    this.pointValidity = null;
    this.result = null;
    this.resultDevice = [];
    this.resultCustomer = [];
    this.userRepo.openSearchUser(this.value)
      .subscribe((data: any) => {
        console.log(data);
        this.resultCustomer = data;
      }, error => {
        this.result = null;
        this.toastRepo.error(error.error.Error.Data, 'Search..');
      });
  }

  details(value: any): void {
    this.lastRepo.getLastLocationsCustomerWise(value.Customer.Id as number).subscribe((d: any[]) => {
      var t = d.filter((element) => {
        return element.Device.Id === value.Device.Id;
      });
      this.pointValidity = t[0].PointValidity;
    });
    this.result = value;
  }

  closeDialog(): void {
    const data = { key: this.search, value: this.value };
    this.dialogRef.close(data);
  }
}

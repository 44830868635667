import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
// Dealer Licence to activate the device
export class DeviceLicenceRepoService {

  private baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiEndPoint;
  }

  applyLicenceToDevice(deviceId: number): any {
    return this.httpClient.post(`${this.baseUrl}/api/DealerLicenceLine`, { DeviceId: deviceId })
      .pipe(map((data: any) => {
        return data.Result.Data;
      }));
  }

  applyLicenceToDeviceV1(ResellerId: number, CustomerId: number, deviceId: number): any {
    return this.httpClient.post(`${this.baseUrl}/api/v1/DealerLicenceLine`, { ResellerId, CustomerId, DeviceId: deviceId })
      .pipe(map((data: any) => {
        return data.Result.Data;
      }));
  }


  getAvailableLicenceCount(dealerId: number): any {
    return this.httpClient.get<any>(`${this.baseUrl}/api/DealerLicenceLine/${dealerId}`)
      .pipe(map((data: any) => {
        return data.Result.Data;
      }));
  }

  getDeviceLicenceDetails(deviceSno: number): any {
    return this.httpClient.get<any>(`${this.baseUrl}/api/DealerLicenceLine/GetDeviceLicence/${deviceSno}`)
      .pipe(map((data: any) => {
        return data.Result.Data;
      }));
  }
}

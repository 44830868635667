import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerUserRepoService {

  baseUrl: string;
  constructor(private client: HttpClient) {
    this.baseUrl = environment.apiEndPoint;
  }

  getCustomerUserList(customerId: number): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/CustomerUser/${customerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getCustomerUserById(customerId: number, userId: number): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/CustomerUser/GetById/${customerId}/${userId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  createCustomerUser(user: any): Observable<any> {
    return this.client.post(`${this.baseUrl}/api/CustomerUser`, user)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  createCustomerUserV1(user: any): Observable<any> {
    return this.client.post(`${this.baseUrl}/api/v1/CustomerUser`, user)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getCustomerUserListV1(resellerId: number, customerId: number): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerUser/${resellerId}/${customerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  updateCustomerUser(id: number, user: any): Observable<any> {
    return this.client.put(`${this.baseUrl}/api/CustomerUser/${id}`, user)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  updateCustomerUserV1(id: number, user: any): Observable<any> {
    console.log(user);
    return this.client.put(`${this.baseUrl}/api/v1/CustomerUser/${id}`, user)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  checkDuplicateLoginId(id: number, loginId: string): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/CustomerUser/duplicate-login/${id}/${loginId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  openSearchUser(value: string): Observable<number> {
    return this.client.get(`${this.baseUrl}/api/CustomerUser/OpenSearch/${value}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  checkDuplicateLoginIdV1(id: number, loginId: string): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerUser/duplicate-login/${id}/${loginId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  changePassword(data: any): Observable<any> {
    return this.client.post(`${this.baseUrl}/api/v1/CustomerUser/CustomerChangePassword`, data)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }
}

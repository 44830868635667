import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { DealerLayoutComponent } from './layouts/dealer/dealer-layout.component';
import { CustomerLayoutComponent } from './layouts/customer/customer-layout.component';
import { HomeLayoutComponent } from './layouts/home/home-layout.component';
import { AdminHeaderComponent } from './shared/admin-header/admin-header.component';
import { AdminFooterComponent } from './shared/admin-footer/admin-footer.component';
import { DealerFooterComponent } from './shared/dealer-footer/dealer-footer.component';
import { DealerHeaderComponent } from './shared/dealer-header/dealer-header.component';
import { CustomerHeaderComponent } from './shared/customer-header/customer-header.component';
import { CustomerFooterComponent } from './shared/customer-footer/customer-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgxPrintModule } from 'ngx-print';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { MatTableExporterModule } from 'mat-table-exporter';
import { QRCodeModule } from 'angularx-qrcode';
import { RawDataComponent } from './raw-data/raw-data.component';
import { EmrRawDataComponent } from './emr-raw-data/emr-raw-data.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTableFilterModule } from 'mat-table-filter';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgbModalDraggableModule } from 'ngb-modal-draggable';
// const config: SocketIoConfig = { url: 'redis-10293.c212.ap-south-1-1.ec2.cloud.redislabs.com:10293', options: {} };
const config: SocketIoConfig = { url: '103.135.36.169:6379', options: {} };
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    DealerLayoutComponent,
    CustomerLayoutComponent,
    HomeLayoutComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    DealerFooterComponent,
    DealerHeaderComponent,
    CustomerHeaderComponent,
    CustomerFooterComponent,
    RawDataComponent,
    EmrRawDataComponent
  ],
  imports: [
    SocketIoModule.forRoot(config),
    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatTableExporterModule,
    QRCodeModule,
    NgxPrintModule,
    NgbModule,
    NgxChartsModule,
    MatTableFilterModule,
    NgbModalDraggableModule,
    NgBootstrapFormValidationModule.forRoot()
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatTableExporterModule,
    NgbModule,
    NgxSpinnerModule,
    NgxChartsModule,
    MatTableFilterModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, DatePipe, DecimalPipe],
  // providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

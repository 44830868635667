import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  setToken(token: string): void {
    localStorage.setItem('tokenKey', token);
  }

  retrieve(): string {
    return localStorage.getItem('tokenKey');
  }

  delete(): void {
    localStorage.removeItem('tokenKey');
  }

  getRole(): number {
    const token = localStorage.getItem('tokenKey');
    try {
      const decode = jwt_decode(token);
      // tslint:disable-next-line: no-string-literal
      return Number(decode['role']);
    }
    catch (Error) {
      return null;
    }
  }

  getTokenDetails(): any {
    const token = localStorage.getItem('tokenKey');
    try {
      const decode = jwt_decode(token);
      return decode;
    }
    catch (Error) {
      return null;
    }
  }
}

<div style="padding: 10px;" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
    <div><h3>Emergency Data</h3></div>
    <div fxFlex fxLayout="row" fxLayoutGap="10px">
        <div><input [(ngModel)]="deviceId" required></div>
        <div><button (click)="setValue()">Records</button></div>
    </div>
    <div *ngIf="result.length!==0">
        <table class="styled-table">
            <thead>
                <tr>
                    <th>Date Time</th>
                    <th>IMEI</th>
                    <th>Raw Data</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of result">
                    <td>{{row.RecTime | date: 'yyyy-MMM-dd HH:mm:ss'}}</td>
                    <td>{{row.DeviceId}}</td>
                    <td>{{row.Data}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
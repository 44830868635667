import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LastPoint } from '../models/last-point';
import { DashboardSummary } from '../models/dashboard-summary';
@Injectable({
  providedIn: 'root'
})
export class LastPointService {

  constructor(private httpClient: HttpClient) { }

  getLastLocations(): Observable<LastPoint[]> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/VehicleList`)
      .pipe(map((res: any) => {
        return res.Result.Data as LastPoint[];
      }));
  }

  getLastLocation(id: number): Observable<LastPoint> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/VehicleList/SearchByVehicle/${id}`)
      .pipe(map((res: any) => {
        return res.Result.Data as LastPoint;
      }));
  }

  getLastLocationsCustomerWise(customerId: number): Observable<LastPoint[]> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/VehicleList/${customerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data as LastPoint[];
      }), catchError((err: any) => {
        return throwError(err);
      }));
  }

  getDealerDashboardSummary(dealerId: number): Observable<DashboardSummary> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/VehicleList/Reseller/${dealerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data as DashboardSummary;
      }), catchError((err: any) => {
        return throwError(err);
      }));
  }

  getDealerDashboardDetailSummary(dealerId: number, statusId: number): Observable<DashboardSummary> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/VehicleList/Reseller/Detailed/${dealerId}/${statusId}`)
      .pipe(map((res: any) => {
        return res.Result.Data as DashboardSummary;
      }), catchError((err: any) => {
        return throwError(err);
      }));
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerLayoutComponent } from './layouts/customer/customer-layout.component';
import { DealerLayoutComponent } from './layouts/dealer/dealer-layout.component';
import { HomeLayoutComponent } from './layouts/home/home-layout.component';
import { HOME_ROUTES } from './routes/home-routes';
import { LAYOUT_ROUTES } from './routes/layout-routes';
const routes: Routes = [
  { path: '', component: HomeLayoutComponent, children: HOME_ROUTES },
  { path: 'auth', component: CustomerLayoutComponent, children: LAYOUT_ROUTES },
  { path: 'dauth', component: DealerLayoutComponent, children: LAYOUT_ROUTES },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../..//environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RawDataRepoService {

  constructor(private httpClient: HttpClient) { }

  getRawData(data: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/RawData/${data}/1000`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getEmergencyRawData(data: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/RawData/Emergency/${data}/1000`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  sendPacket(imei: string, command: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiEndPoint}/api/RawData?id=${imei}&command=${command}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }
}

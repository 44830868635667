import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BalancePointComponent } from 'src/app/dealer/balance-point/balance-point.component';
import { DeviceTypeListComponent } from 'src/app/dealer/device-type/device-type-list/device-type-list.component';
import { SerachComponent } from 'src/app/dealer/serach/serach.component';
import { DeviceLicenceRepoService } from 'src/app/services/device-licence-repo.service';
import { GenericPubSubService } from 'src/app/services/generic-pub-sub.service';
import { PubsubService } from 'src/app/services/pubsub.service';
import { RefreshStopPubSubService } from 'src/app/services/refresh-stop-pub-sub.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-dealer-layout',
  templateUrl: './dealer-layout.component.html',
  styleUrls: ['./dealer-layout.component.scss']
})
export class DealerLayoutComponent implements OnInit, OnDestroy {

  isDealer = true;
  dealerId: number;
  sub: Subscription;
  licenceCount = 0;
  constructor(private breakpointObserver: BreakpointObserver,
    // tslint:disable-next-line: align
    private tokenRepo: TokenService,
    // tslint:disable-next-line: align
    private route: Router,
    // tslint:disable-next-line: align
    public dialog: MatDialog,
    // tslint:disable-next-line: align
    private pubsub: GenericPubSubService<number>,
    // tslint:disable-next-line: align
    private deviceLicenceRepo: DeviceLicenceRepoService,
    // tslint:disable-next-line: align
    private pubsubLogout: PubsubService,
    private refreshPubSub: RefreshStopPubSubService) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    if (this.tokenRepo.getRole() !== 1) {
      this.isDealer = false;
    }

    this.sub = this.pubsub.observable.subscribe((data: number) => {
      if (data !== null) {
        this.dealerId = Number(data);
        // if (data instanceof Number) {
        this.deviceLicenceRepo.getAvailableLicenceCount(Number(data))
          .subscribe((result: any) => {
            this.licenceCount = result;
          });
        // }
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  logout(): void {
    this.pubsubLogout.Broadcast(1);
    this.tokenRepo.delete();
    this.route.navigate(['.']);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeviceTypeListComponent, {
      width: '40vw',
      maxHeight: '70vh'
    });
  }

  showBalancePoints(): void {
    const dialogRef = this.dialog.open(BalancePointComponent, {
      width: '20vw',
      maxHeight: '30vh',
      data: this.dealerId
    });
  }

  changePassword(): void {

  }

  searchLayout(): void {
    this.refreshPubSub.Broadcast(false);
    const dialogRef = this.dialog.open(SerachComponent, {
      width: '50vw',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.refreshPubSub.Broadcast(true);
    });
  }
}

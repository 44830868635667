<div class="card mat-elevation-z8">
    <div class="card-header">
        Balance Point
    </div>
    <div class="card-body">
        <div fxFlex fxLayout="column" *ngFor="let point of points">
            <p class="card-text">{{point.Count}}</p>
            <h5 class="card-title"><b>{{point.PointName}}</b></h5>
        </div>
    </div>
</div>
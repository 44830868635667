import { Component } from '@angular/core';
import { ValidateUrlService } from './services/validate-url.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'customer-portal';

  constructor(private urlRepo: ValidateUrlService) {
    // console.log(`App component loaded: ${window.location.href}`);
    // const url = 'http://abhishek.gpsapphub.com/';
    // urlRepo.validateUrl(url).subscribe(result => {
    //   console.log(result);
    // });
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private tokenRepo: TokenService,
    // tslint:disable-next-line: align
    private router: Router) { }

  private handleAuthError(): void {
    this.tokenRepo.delete();
    this.router.navigateByUrl('');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('ShareLocation') || request.url.includes('Geocode')) {
      return next.handle(request);
    }

    if (!request.url.includes('CLogin') || !request.url.includes('DLogin')) {
      if (this.tokenRepo.retrieve() === null || this.tokenRepo.retrieve() === '') {
        this.router.navigate(['.']);
      }
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.tokenRepo.retrieve()}`,
        // 'Content-Type': 'application/json'
      }
    });

    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err.status === 401) {
          this.handleAuthError();
          return of(err);
        } else if (err.status === 306) {
          err.error.Error.Message.forEach(element => {
            // this.toastR.error(`${element.PropertyName} : ${element.ErrorMessage}`, 'Validation Error');
          });
        } else if (err.status === 500) {

        }
        throw err;
      })
    );
  }
}

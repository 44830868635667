<div fxLayout="column" fxLayoutAlign="end end">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
        <!-- OrgName Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Ip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IP </th>
            <td mat-cell *matCellDef="let row"> {{row.Ip}} </td>
        </ng-container>

        <!-- MobileNo Column -->
        <ng-container matColumnDef="Port">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Port </th>
            <td mat-cell *matCellDef="let row"> {{row.Port}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
import { Routes } from '@angular/router';

export const LAYOUT_ROUTES: Routes = [
    {
        path: 'dealer',
        loadChildren: () => import('../dealer/dealer.module').then(m => m.DealerModule)
    }, {
        path: 'admin',
        loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule)
    }, {
        path: 'customer',
        loadChildren: () => import('../customer/customer.module').then(m => m.CustomerModule)
    }
];

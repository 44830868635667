import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerDevices } from '../models/customer-devices';
import { CustomerDevice } from '../models/customer-device';

@Injectable({
  providedIn: 'root'
})
export class DeviceRepoService {
  private baseUrl: string;
  private deviceList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly deviceObserver = this.deviceList.asObservable();

  constructor(private client: HttpClient) {
    this.baseUrl = environment.apiEndPoint;
  }

  getCustomerDeviceList(customerId: number): any {
    return this.client.get(`${this.baseUrl}/api/CustomerDevice/${customerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getCustomerDeviceListV1(resellerId: number, customerId: number): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerDevice/${resellerId}/${customerId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getCustomerDeviceById(customerId: number, deviceId: number): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/CustomerDevice/${customerId}/${deviceId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }
  getCustomerDeviceByIdV1(resellerId: number, customerId: number, deviceId: number): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerDevice/${resellerId}/${customerId}/${deviceId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  searchDeviceImei(imei: string): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/CustomerDevice/SearchImei/${imei}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }


  createCustomerDevice(user: any): Observable<any> {
    return this.client.post(`${this.baseUrl}/api/CustomerDevice`, user)
      .pipe(map((res: any) => {
        this.deviceList.next(res.Result.Data);
        return res.Result.Data;
      }));
  }

  createCustomerDeviceV1(user: any): Observable<any> {
    return this.client.post(`${this.baseUrl}/api/v1/CustomerDevice`, user)
      .pipe(map((res: any) => {
        this.deviceList.next(res.Result.Data);
        return res.Result.Data;
      }));
  }

  deleteCustomerDevice(customerId: number, deviceId: number): Observable<any> {
    return this.client.delete(`${this.baseUrl}/api/CustomerDevice/${customerId}/${deviceId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  deleteCustomerDeviceV1(resellerId: number, customerId: number, deviceId: number): Observable<any> {
    return this.client.delete(`${this.baseUrl}/api/v1/CustomerDevice/${resellerId}/${customerId}/${deviceId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  updateCustomerDevice(id: number, device: any): Observable<any> {
    return this.client.put(`${this.baseUrl}/api/CustomerDevice/${id}`, device)
      .pipe(map((res: any) => {
        this.deviceList.next(res.Result.Data);
        return res.Result.Data;
      }));
  }

  updateCustomerDeviceV1(id: number, device: any): Observable<any> {
    return this.client.put(`${this.baseUrl}/api/v1/CustomerDevice/${id}`, device)
      .pipe(map((res: any) => {
        this.deviceList.next(res.Result.Data);
        return res.Result.Data;
      }));
  }

  validateDuplicateDeviceId(id: number, deviceId: any): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/CustomerDevice/duplicate-device/${id}/${deviceId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  validateDuplicateDeviceIdV1(id: number, deviceId: any): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerDevice/DuplicateDeviceId/${id}/${deviceId}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  validateDuplicateDeviceIdImeiV1(id: number, deviceImei: any): Observable<any> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerDevice/DuplicateDeviceId/${id}/${deviceImei}`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  getCustomerDevices(): Observable<CustomerDevices[]> {
    return this.client.get(`${this.baseUrl}/api/v1/CustomerDevice`)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  updateDeviceByCustomer(id: number, details: CustomerDevice): Observable<number> {
    return this.client.put(`${this.baseUrl}/api/v1/CustomerDevice/CustomerUpdateDevice/${id}`, details)
      .pipe(map((res: any) => {
        return res.Result.Data;
      }));
  }

  openSearchDevice(value: string): Observable<number> {
    return this.client.get(`${this.baseUrl}/api/CustomerDevice/OpenSearch/${value}`)
    .pipe(map((res: any) => {
      return res.Result.Data;
    }));
  }

  triggerChange(): void {
    this.deviceList.next(1);
  }
}
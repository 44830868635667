import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshStopPubSubService {

  constructor() { }
  
  readonly observable = new Subject<boolean>();

  Broadcast(data: boolean): void {
    this.observable.next(data);
  }
}

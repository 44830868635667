// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndPoint: 'https://s1-api.globalvts.in',
  fitmentDoc: 'https://s1-api.globalvts.in/Content/',
  coreApiEndPoint: 'https://api.baliniot.in',
  // apiEndPoint: 'http://localhost:39750',
  // fitmentDoc: 'http://localhost:39750/Content/'

  // apiEndPoint: 'https://gpssoftware.in/web_api',
  // fitmentDoc: 'https://gpssoftware.in/web_api/Content/'

  // apiEndPoint: 'https://gpssoftware.in/web_api',
  // fitmentDoc: 'https://gpssoftware.in/web_api/api/Content/'

  // apiEndPoint: 'http://ais.gpsapphub.com',
  // fitmentDoc: 'http://ais.gpsapphub.com/Content/'

  // apiEndPoint: 'http://gps.acutecommunications.in/api',
  // fitmentDoc: 'http://gps.acutecommunications.in/api/Content/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

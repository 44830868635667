import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { RawDataRepoService } from '../services/raw-data-repo.service';
@Component({
  selector: 'app-raw-data',
  templateUrl: './raw-data.component.html',
  styleUrls: ['./raw-data.component.scss']
})
export class RawDataComponent implements OnInit {
  deviceId = '';
  command = '';
  result: any[] = [];
  interval: any = null;
  buttonText = 'Search';
  counter = 10;

  constructor(private repo: RawDataRepoService,
    // tslint:disable-next-line: align
    private toastR: ToastrService) { }

  ngOnInit(): void {

  }

  setValue(): void {
    if (this.deviceId === '') {
      this.toastR.error('Please enter the Imei ', 'Invalid Imei');
      return;
    }

    if (this.interval === null) {
      console.log('Interval Start');
      this.interval = setInterval(() => {
        this.loadData();
      }, 1000);
    }
    else {
      console.log('Interval Stop');
      this.buttonText = 'Search';
      clearInterval(this.interval);
      this.interval = null;
      this.counter = 10;
    }

  }

  sendCommand(): void {
    this.repo.sendPacket(this.deviceId, this.command)
      .subscribe((d: any) => {
        console.log(d);
      });
  }

  loadData(): void {
    this.buttonText = 'Refresh in ' + this.counter + ' Sec';
    console.log(this.buttonText);
    if (this.counter > 1) {
      this.counter--;
      return;
    }

    this.counter = 10;
    this.repo.getRawData(this.deviceId)
      .subscribe((d: any) => {
        this.result = d;
      });
  }
}

<div fxLayout="row" style="padding: 10px;" fxLayoutGap="20px" fxLayoutAlign='end center'>
    <!-- <mat-form-field floatLabel="always">
        <mat-label>Search Parameter</mat-label>
        <mat-select [(ngModel)]="search" (selectionChange)="filterChange($event)">
            <mat-option>
                <ngx-mat-select-search [(ngModel)]="searchCtrl" [placeholderLabel]="'Search...'"
                    [noEntriesFoundLabel]="'Not found'" name="search"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let sFilter of searchFilter | dropdownSearch:searchCtrl:'Name'" [value]="sFilter.Id">
                {{sFilter.Name}}
            </mat-option>
        </mat-select> 
    </mat-form-field> -->
    <mat-form-field floatLabel="always">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search" required [(ngModel)]="value" autocomplete="off">
    </mat-form-field>
    <button fxFlexAlign="baseline" mat-raised-button color="primary" (click)="searchDevice()">Device</button>
    <button fxFlexAlign="baseline" mat-raised-button color="primary" (click)="searchUser()">User</button>
</div>

<div *ngIf="resultDevice.length!==0" class="table-responsive-sm">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Dealer</th>
                <th scope="col">Customer</th>
                <th scope="col">Installed On.</th>
                <th scope="col">Vehicle No.</th>
                <th scope="col">Sim Phone No.</th>
                <th scope="col">IMEI.</th>
                <th scope="col">#</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let device of resultDevice">
                <td>{{device.Dealer.Name}}</td>
                <td>{{device.Customer.CustomerName}}</td>
                <td>{{device.Device.InstallationOn|date:'YYYY-MM-dd'}}</td>
                <td>{{device.Device.VehicleNo}}</td>
                <td>{{device.Device.SimPhoneNumber}}</td>
                <td>{{device.Device.DeviceImei}}</td>
                <td>
                    <button type="button" (click)="details(device)" class="btn btn-primary btn-sm">Details</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="resultCustomer.length!==0" class="table-responsive-sm">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Dealer</th>
                <th scope="col">Customer</th>
                <th scope="col">Login Id</th>
                <th scope="col">Pasword</th>
                <th scope="col">Mobile</th>
                <th scope="col">Email</th>
                <th scope="col">#</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cust of resultCustomer">
                <td>{{cust.Dealer.Name}}</td>
                <td>{{cust.Customer.CustomerName}}</td>
                <td>{{cust.Customer.User.LoginId}}</td>
                <td>{{cust.Customer.User.Password}}</td>
                <td>{{cust.Customer.ContactNumber}}</td>
                <td>{{cust.Customer.Email}}</td>
                <td>
                    <button type="button" (click)="details(cust)" class="btn btn-primary btn-sm">Details</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="container" *ngIf="result!==null">
    <div class="flexChild">
        <div style="align-self: center; color: whitesmoke; font-size: 14px; font-weight: bold;">
            Dealer</div>
        <div style="align-self: center; color: whitesmoke;">{{result.Dealer.Name}} [{{result.Dealer.OrgName}}]</div>
    </div>
    <div class="flexChild">
        <div style="align-self: center; color: whitesmoke; font-size: 14px; font-weight: bold;">
            Customer</div>
        <div style="align-self: center; color: whitesmoke;">{{result.Customer.CustomerName}}</div>
    </div>
    <div class="flexChild" *ngIf="result.Device!==undefined">
        <div fxFlex fxLayout="row" fxLayoutGap="20px">
            <div style="align-self: center; color: whitesmoke; font-size: 11px; font-weight: bold;">
                Device</div>
            <div style="align-self: center; font-size: 10px; color: whitesmoke;">
                {{result.Device.VehicleNo}}</div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="20px">
            <div style="align-self: center; color: whitesmoke; font-size: 11px; font-weight: bold;">
                Installation On</div>
            <div style="align-self: center; font-size: 10px; color: whitesmoke;">
                {{result.Device.InstallationOn|date:'YYYY-MM-dd'}}</div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="20px">
            <div style="align-self: center; color: whitesmoke; font-size: 11px; font-weight: bold;">
                Point Due</div>
            <div style="align-self: center; font-size: 10px; color: whitesmoke;">
                {{pointValidity.NextRechargeDue|date:'YYYY-MM-dd'}}</div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="20px">
            <div style="align-self: center; color: whitesmoke; font-size: 11px; font-weight: bold;">
                C. Recharge Due</div>
            <div style="align-self: center; font-size: 10px; color: whitesmoke;">
                {{pointValidity.CustomerRechargeDue|date:'YYYY-MM-dd' }}</div>
        </div>
    </div>
</div>
<mat-sidenav-container class="example-container" fxLayout="row">
    <mat-sidenav fxFlex="60" #sidenav mode="side" opened="true" style="background-color: #24313d;">
        <mat-nav-list fxFlex fxLayout="column" fxLayoutAlign="center center" style="height: 95vh;">
            <a mat-list-item routerLink="customer/profile" style="margin-top: 30px;" fxLayout="column"
                fxLayoutAlign="center center" matTooltip="Profile" matTooltipPosition="right" matTooltipHideDelay="100">
                <span matLine class="icn_log"
                    style="background-image: url('http://images.protrack365.com/portrait/default.jpg');"></span>
            </a>
            <a mat-list-item routerLink="customer/dashboard" routerLinkActive="active-link" style="margin-top: 30px;"
                fxLayout="column" fxLayoutAlign="center center" matTooltip="Dashboard" matTooltipPosition="right"
                matTooltipHideDelay="100">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>language</mat-icon>
                </span>
                <span matLine style="color: blanchedalmond; font-size: 13px;">Map</span>
            </a>
            <a mat-list-item routerLink="customer/report" routerLinkActive="active-link" fxLayout="column"
                matTooltip="Reports" matTooltipPosition="right" matTooltipHideDelay="100" fxLayoutAlign="center center">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>leaderboard</mat-icon>
                </span>
                <span matLine style="color: blanchedalmond; font-size: 13px;">Reports</span>
            </a>
            <a mat-list-item routerLink="customer/device" routerLinkActive="active-link" fxLayout="column"
                matTooltip="Devices" matTooltipPosition="right" matTooltipHideDelay="100" fxLayoutAlign="center center">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>directions_car</mat-icon>
                </span>
                <span matLine style="color: blanchedalmond; font-size: 13px;">Settings</span>
            </a>
            <a mat-list-item routerLink="customer/users" routerLinkActive="active-link" fxLayout="column"
               matTooltip="Devices" matTooltipPosition="right" matTooltipHideDelay="100" fxLayoutAlign="center center">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>supervisor_account</mat-icon>
                </span>
                <span matLine style="color: blanchedalmond; font-size: 13px;">Users</span>
            </a>
            <a mat-list-item routerLink="customer/list-geofence" routerLinkActive="active-link" fxLayout="column"
                matTooltip="Geofence" matTooltipPosition="right" matTooltipHideDelay="100"
                fxLayoutAlign="center center">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>home_work</mat-icon>
                </span>
                <span matLine style="color: blanchedalmond; font-size: 13px;">Geofence</span>
            </a>
            <div fxFlex="55"></div>
            <a mat-list-item routerLink="customer/message" routerLinkActive="active-link" fxLayout="column"
                matTooltip="Message" matTooltipPosition="right" matTooltipHideDelay="100" fxLayoutAlign="center center">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>mail_outline</mat-icon>
                </span>
            </a>
            <a role="button" mat-list-item fxLayout="column" matTooltip="Logout" matTooltipPosition="right"
                matTooltipHideDelay="100" fxLayoutAlign="center center" (click)="logout()">
                <span matLine fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>exit_to_app</mat-icon>
                </span>
            </a>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content fxFlex="95">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- <mat-sidenav-container class="example-container" autosize>
    <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
        <mat-nav-list>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" fxFlex fxLayout="column"
                fxLayoutAlign="center center">
                <a mat-list-item routerLink="#" routerLinkActive="active-link" style="margin-top: 30px;"
                    fxLayout="column" fxLayoutAlign="center center">
                    <span matLine class="icn_log"
                        style="background-image: url('http://images.protrack365.com/portrait/default.jpg');"></span>
                </a>
            </mat-list-item>
        </mat-nav-list>
        <mat-nav-list>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Parent Menu</span>
                <mat-icon mat-list-icon>home</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">
                    expand_more
                </mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
                <a mat-list-item href="...">Submenu Item 1</a>
                <a mat-list-item href="...">Submenu Item 2</a>
                <mat-list-item (click)="showSubSubMenu = !showSubSubMenu" class="parent">
                    <span class="full-width" *ngIf="isExpanded || isShowing">Nested Menu</span>
                    <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}"
                        *ngIf="isExpanded || isShowing">
                        expand_more</mat-icon>
                </mat-list-item>
                <div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="isShowing || isExpanded">
                    <mat-list-item>SubSubmenu Item 1</mat-list-item>
                    <mat-list-item>SubSubmenu Item 2</mat-list-item>
                </div>
            </div>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container> -->

<!-- <mat-sidenav-container class="example-container">
    <mat-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container> -->

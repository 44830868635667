import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PubsubService {

  private data: any;
  readonly observable = new BehaviorSubject<any>(null);

  constructor() { }

  Broadcast(data: any): void {
    this.observable.next(data);
  }
}

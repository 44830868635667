<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="dealer/dashboard">Dashboard</a>
            <a mat-list-item *ngIf="isDealer" routerLink="dealer/reseller">Reseller</a>
            <a mat-list-item routerLink="dealer/customer">Customer</a>
            <a mat-list-item routerLink="dealer/customer-sub-user">Sub User</a>
            <a mat-list-item routerLink="dealer/device">Device</a>
            <a mat-list-item routerLink="dealer/reports">Reports</a>
            <a mat-list-item routerLink="dealer/pointSummary">Points Summary</a>
            <a mat-list-item routerLink="dealer/profile">Profile</a>
            <!-- <a mat-list-item routerLink="dealer/fitment-master">Fitment</a> -->
            <a role="button" mat-list-item (click)="logout()">Logout</a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span class="example-spacer"></span>
            <!-- <button (click)="changePassword()" mat-icon-button class="example-icon favorite-icon"
                aria-label="Example icon-button with heart icon">
                <mat-icon>Person</mat-icon>
            </button> -->
            <button (click)="searchLayout()" mat-icon-button class="example-icon favorite-icon"
                aria-label="Example icon-button with heart icon">
                <mat-icon>search</mat-icon>
            </button>
            <button (click)="openDialog()" mat-icon-button class="example-icon favorite-icon"
                aria-label="Example icon-button with heart icon">
                <mat-icon>toc</mat-icon>
            </button>
            <button (click)="showBalancePoints()" mat-icon-button class="example-icon favorite-icon"
                aria-label="Example icon-button with heart icon">
                <mat-icon>analytics</mat-icon>
            </button>


            <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                <mat-icon>search</mat-icon>
            </button> -->

            <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                <mat-icon>share</mat-icon>
            </button> -->
        </mat-toolbar>
        <!-- Add Content Here -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericPubSubService<T> {
  private data: T;
  readonly observable = new BehaviorSubject<T>(null);

  constructor() { }

  Broadcast(data: T): void {
    this.observable.next(data);
  }
}
